import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import "vuetify/dist/vuetify.min.css";
import "@/assets/scss/main.scss";
import "@/components/global";
import "@/utils/validators";
import "@/utils/filters";
import "@/router/guards";
import { status, lookupTypes } from "./utils/common";
import { hasPermission } from "./utils/helpers";


Vue.config.productionTip = false
Vue.prototype.$status = status;
Vue.prototype.$lookupTypes = lookupTypes;
Vue.prototype.$hasPermission = hasPermission;



new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
