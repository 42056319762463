import dataService from "../../api";
import { startLoading, stopLoading, serverErrorMessage, showAlert, catchHandler } from "../../utils/helpers";


const state = {

    qualifications: [],
    totalItems: 0,
};

const getters = {
};

const mutations = {

    setQualifications: (state, r) => (state.qualifications = r),
    setTotalItems: (state, r) => (state.totalItems = r),

};

const actions = {


    async getQualifications({ commit }, params) {
        try {
            const paramsString = Object.entries(params)
                .filter(([, val]) => val !== null)
                .map(([key, val]) => `${key}=${val}`)
                .join("&");
            startLoading()
            const response = await dataService.qualifications.getQualifications(paramsString);
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            commit("setQualifications", response.data.qualifications);

            commit("setTotalItems", response.data.totalItems);


        } catch (error) {
            stopLoading()
            catchHandler(error);
        }
    },





};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
