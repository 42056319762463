import apiClient from "../apiClient";

const resource = "Security";

export default {
 Login: payload => apiClient.post(`${resource}/login`, payload),
 LoginStatus: () => apiClient.get(`${resource}/loginStatus`),
 Logout: () => apiClient.post(`${resource}/logout`),
 resetSession: () => apiClient.get(`${resource}/resetSession`),

};
