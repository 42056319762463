import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import ar from 'vuetify/es5/locale/ar'
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
	lang: {
		locales: { ar },
		current: 'ar',
	},
  theme: {
    default: "light",
		disable: false,
		options: {
			cspNonce: undefined,
			customProperties: true,
			minifyTheme: undefined,
			themeCache: undefined,
		},
		icons: {
			iconfont: 'mdi', // default - only for display purposes
		},
    themes: {
      light: {
        primary: '#398ecb',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
});
