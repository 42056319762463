import dataService from "../../api";
import { startLoading, stopLoading, serverErrorMessage, showAlert, showNotification, catchHandler } from "../../utils/helpers";

const state = {
    isLoggedIn: false,
    permissions: [],
    user:{},
    lastRequestOn :  new Date(),

};

const getters = {
    hasPermission: (state) => (permissionId) => {
        return state.permissions.includes(permissionId)
    },
}

const mutations = {
    setLastRequestOn: (state) => (state.lastRequestOn =  new Date()),
    setLoginState: (state, isLoggedIn) => (state.isLoggedIn = isLoggedIn),
    setPermissions: (state, permissions) => (state.permissions = permissions),
    setUserInfo: (state, user) => (state.user = user),
};

const actions = {
    async login({ commit }, payload) {
        try {
            startLoading()
            const response = await dataService.security.Login(payload);
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            if (response.data.authenticatedUser.status!=7) {
                commit("setLoginState", true);
            }
             var user ={
                userId: response.data.authenticatedUser.userId,
                loginName: response.data.authenticatedUser.loginName,
                status: response.data.authenticatedUser.status,
                userTypeId: response.data.authenticatedUser.userTypeId,
                fullName: response.data.authenticatedUser.fullName,

            }
            commit("setUserInfo", user);
            commit("setPermissions", response.data.authenticatedUser.permissions);

            return Promise.resolve(response.data);

        } catch (error) {
            stopLoading()
            catchHandler(error);
            return Promise.reject(error);
        }
    },   
 
    async loginStatus({ commit }) {
        try {
            const response = await dataService.security.LoginStatus();

            if (!response.data.statusCode) {
                // showAlert(serverErrorMessage);
                return;
            }
            var user = {
                userId: response.data.authenticatedUser.userId,
                loginName: response.data.authenticatedUser.loginName,
                status: response.data.authenticatedUser.status,
             userTypeId: response.data.authenticatedUser.userTypeId,
             fullName: response.data.authenticatedUser.fullName,
            }
            if (response.data.authenticatedUser.status!=7) {
                commit("setLoginState", true);
            }
            commit("setUserInfo", user);    
            commit("setPermissions", response.data.authenticatedUser.permissions);
         
        } catch (error) {
            commit("setLoginState", false);
        }
    },
	async logout({ commit }) {
        try {
            startLoading()
            const response = await dataService.security.Logout();
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            showNotification(response.data.message)

            resetStore(commit);
        } catch (error) {
            stopLoading()
            resetStore(commit);
        }
    },
    async resetSession({ commit }) {
        try {
            startLoading()
            const response = await dataService.security.resetSession();
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }
        } catch (error) {
            stopLoading()
            resetStore(commit);

            // catchHandler(error);
            // return Promise.reject(error);
        }
    },
   
}


function resetStore(commit) {
    commit("setLoginState", false);
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};