import dataService from "../../api";
import { startLoading, stopLoading, serverErrorMessage,showNotification, showAlert, catchHandler } from "../../utils/helpers";

const state = {
    complaints: [],
    complaint: {},
    totalItems: 0,

};

const getters = {
    index: state => complaintId => state.complaints.findIndex(i => i.complaintId === complaintId)
};

const mutations = {
    setComplaints: (state, r) => (state.complaints = r),
    setTotalItems: (state, r) => (state.totalItems = r),
    setComplaint: (state, r) => (state.complaint = r),
    add: (state, r) => state.complaints.unshift(r),

 

};

const actions = {
    async getAll({ commit }, params) {
        try {
            const paramsString = Object.entries(params)
                .filter(([, val]) => val !== null)
                .map(([key, val]) => `${key}=${val}`)
                .join("&");
            startLoading()
            const response = await dataService.complaints.getAll(paramsString);
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            commit("setComplaints", response.data.complaints);
            commit("setTotalItems", response.data.totalItems);


        } catch (error) {
            stopLoading()
            catchHandler(error);
        }
    },
   
    async getDetails({ commit }, id) {
        try {
            commit("setComplaint", {});
            startLoading()
            const response = await dataService.complaints.getDetails(id);
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            commit("setComplaint", response.data.complaint);

        } catch (error) {
            stopLoading()
            catchHandler(error);
        }
    },

    async add({ commit }, payload) {
        try {
            startLoading()
            const response = await dataService.complaints.add(payload);
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            showNotification(response.data.message);
            commit("add", response.data.complaint);


        } catch (error) {
            stopLoading()
            catchHandler(error);
            return Promise.reject(error);
        }
    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
