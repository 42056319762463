export const lookupTypes = {
    AdministrationTypes: 1,
    Correlators: 2,
    SourceTypes: 3,
    Genders: 4,
    SocialStatus: 5,
    BloodTypes:6,
    HealthStatus: 7,
    ContractTypes: 8,
    IdentificationTypes: 10,
    AppointmentTypes: 11,
    HealthDiseaseTypes: 12,
    CourseTypes: 13,
    UserTypes: 14,
    AssignmentTypes: 15,
    TransferTypes: 16,
    TransferReasons: 17,
    NotificationsTypes: 18,
    ComplaintTypes: 19,
};
export const status = {
    dataEnter: 10,
    active: 1,
    locked: 2,
    rejected: 4,
    approved: 5,
    expired: 11,
};


export const gender = {
    male: 1,
    female: 2
};