import apiClient from "../apiClient";

const resource = "complaints";

export default {
    getAll: params => apiClient.get(`${resource}?${params}`),
    reply: (id, payload) => apiClient.put(`${resource}/${id}/Reply`, payload),
    getDetails: id => apiClient.get(`${resource}/${id}/details`),
    add: payload => apiClient.post(`${resource}`, payload),

};
