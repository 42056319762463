import Vue from "vue";
import Vuex from "vuex";
//........................................ UI Modules.....................................................
import alert from "./modules/ui/alert";
import loading from "./modules/ui/loading";
import notification from "./modules/ui/notification";
import sidebar from "./modules/ui/sidebar";
import lookups from "./modules/lookups";
import security from "./modules/security";

//........................................employeesManagement...............................................
import employee from "./modules/employee";
import health from "./modules/health";
import courses from "./modules/courses";
import qualifications from "./modules/qualifications";
import leaves from "./modules/leaves";
import complaints from "./modules/complaints";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        // UI Modules
        alert,
        loading,
        notification,
        sidebar,
        lookups,
        security,

       
        employee,
        health,
        courses,
        qualifications,
        leaves,
        complaints,
       

    },
});