<template>
   <div>
  <!--  <v-app id="inspire">
      <v-app id="inspire"> -->
        <AppSidebar />
        <AppHeader />

        <v-main>
          <v-container fluid class="page-wrapper">
                    <div class="maxWidth">
                      <v-scroll-y-transition mode="out-in">
                        <router-view></router-view>
                        </v-scroll-y-transition>
                    </div>
                </v-container>
          
        </v-main>

        <AppFooter />
    <!-- </v-app>
    </v-app> -->
  </div>
</template>

<script>
export default {
  components: {
    AppFooter: () => import("./Footer.vue"),
    AppHeader: () => import("./Header.vue"),
    AppSidebar: () => import("./Sidebar.vue"),
  },

  data: () => ({
    drawer: null,
  }),

  created() {
    this.$vuetify.theme.dark = false;
  },
};
</script>
<style lang="scss">

</style>