<template>
    <v-app id="inspire">
        <v-content>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-card class="elevation-12">
                            <v-card-text>
                                <div class="d-flex align-center justify-center" style="height: 80vh">
                                    <v-sheet width="400" class="mx-auto">
                                        <!-- <span class="d-flex align-center justify-center">تسجيل الدخول</span>
                                        <hr> -->
                                        <v-toolbar-title class="d-flex align-center justify-center">منصة الموظفين</v-toolbar-title>
                                        <br>
                                        <!-- <ValidationObserver v-slot="{ handleSubmit }"> -->
                                        <v-form fast-fail @submit.prevent="submit" class="my-5">

                                            <!-- <v-form class="my-5"> -->

                                            <v-text-field v-model="user.loginName" label="اسم المستخدم"></v-text-field>

                                            <v-text-field v-model="user.password"
                                                          :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                                                          :type="isPasswordVisible ? 'text' : 'password'"
                                                          @click:append="isPasswordVisible = !isPasswordVisible"
                                                          label="كلمة المرور"></v-text-field>

                                            <v-btn type="submit" color="primary" block class="mt-2">تسجيل الدخول</v-btn>

                                        </v-form>
                                        <!-- </ValidationObserver> -->
                                    </v-sheet>
                                </div>
                            </v-card-text>

                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
    export default {
        data() {
            return {
                isPasswordVisible: false,

                user: {
                    loginName: null,
                    password: null,
                },

            };
        },
        methods: {
            submit() {
                if (this.user.loginName != null && this.user.password != null) {
                    this.$store
                        .dispatch("security/login", this.user)
                        .then(() => {
                            this.$router.push({ name: "Layout" });
                        })
                        .catch(() => { });
                }
            },
        },
    };
</script>

<style></style>
<template>
    <v-app id="inspire">
        <v-content>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-card class="elevation-12">
                            <v-card-text>
                                <div class="d-flex align-center justify-center" style="height: 80vh">
                                    <v-sheet width="400" class="mx-auto">
                                        <!-- <span class="d-flex align-center justify-center">تسجيل الدخول</span>
                                        <hr> -->
                                        <v-toolbar-title class="d-flex align-center justify-center">منصة الموظفين</v-toolbar-title>
                                        <br>
                                        <!-- <ValidationObserver v-slot="{ handleSubmit }"> -->
                                        <v-form fast-fail @submit.prevent="submit" class="my-5">

                                            <!-- <v-form class="my-5"> -->

                                            <v-text-field v-model="user.loginName" label="اسم المستخدم"></v-text-field>

                                            <v-text-field v-model="user.password"
                                                          :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                                                          :type="isPasswordVisible ? 'text' : 'password'"
                                                          @click:append="isPasswordVisible = !isPasswordVisible"
                                                          label="كلمة المرور"></v-text-field>

                                            <v-btn type="submit" color="primary" block class="mt-2">تسجيل الدخول</v-btn>

                                        </v-form>
                                        <!-- </ValidationObserver> -->
                                    </v-sheet>
                                </div>
                            </v-card-text>

                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
    export default {
        data() {
            return {
                isPasswordVisible: false,

                user: {
                    loginName: null,
                    password: null,
                },

            };
        },
        methods: {
            submit() {
                if (this.user.loginName != null && this.user.password != null) {
                    this.$store
                        .dispatch("security/login", this.user)
                        .then(() => {
                            this.$router.push({ name: "Layout" });
                        })
                        .catch(() => { });
                }
            },
        },
    };
</script>

<style></style>
