<template>
    <v-col :cols="cols" :sm="sm" :md="md" class="py-1">
        <validation-provider :name="label" :rules="rules" v-slot="{ errors }">
            <label class="form-label">
                {{ label }} <span v-if="requiredClass">*</span>
            </label>
            <v-file-input dense
                          outlined
                          filled
                          background-color="white"
                          show-size
                          :placeholder="placeholder"
                          :error-messages="errors[0]"
                          :value="value"
                          @change="change($event)"
                           />
        </validation-provider>
    </v-col>
</template>
<script>
    export default {
        props: {
            value: {
                default: undefined,
            },
            label: {
                type: String,
                required: true,
            },
            placeholder: {
                type: String,
                required: false,
            },
            accept: {
                type: String,
                required: false,
            },
            rules: {
                type: [String, Object],
                default: "",
            },
            cols: {
                type: Number,
                default: 12,
            },
            sm: {
                type: Number,
                default: 6,
            },
            md: {
                type: Number,
                default: 3,
            }
        },
        computed: {
            requiredClass() {
                return this.rules.includes("required");
            },
        },
        methods: {
            change(event) {
                this.$emit("input", event);
                this.$emit("change", event);
            },
        },
    };
</script>