import dataService from "../../api";
import { startLoading, stopLoading, serverErrorMessage, showAlert, catchHandler } from "../../utils/helpers";


const state = {
    
    employee: {
        employment:{},
        financial:{},
        attachments:{},
    },
 
};

const getters = {
};

const mutations = {
   
    setEmployee: (state, r) => (state.employee = r),
   
};

const actions = {
 
    async getEmployee({ commit }, ) {
        try {
            commit("setEmployee", {
                employment:{},
                financial:{},
                attachments:{},
            });
            startLoading()
            const response = await dataService.employee.getEmployee();
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            commit("setEmployee", response.data.employee);

        } catch (error) {
            stopLoading()
            catchHandler(error);
        }
    },
 
  
 
   



};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
