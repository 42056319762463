import dataService from "../../api";
import { serverErrorMessage, showAlert, catchHandler } from "../../utils/helpers";


const state = {

    lookups: [],
    specialties: [],
    universities: [],
    vacationTypes: [],
};

const getters = {
    getLookupByType: state => lookupType => state.lookups.filter((lookup) => lookup.type == lookupType),
    getUniversityByType: state => type => state.universities.filter((x) => x.type == type)

};

const mutations = {
    setLookups: (state, t) => (state.lookups = t),
    setSpecialties: (state, specialties) => (state.specialties = specialties),
    setUniversities: (state, universities) => (state.universities = universities),
    setVacationTypes: (state, vacationTypes) => (state.vacationTypes = vacationTypes),

};

const actions = {

    async getLookups({ commit }) {
        try {
            const response = await dataService.lookups.getLookups();

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }
            const lookups = response.data.lookups;
            commit("setLookups", lookups);

        } catch (error) {
            catchHandler(error);
        }
    },
    async getSpecialties({ commit }) {
        try {
            const response = await dataService.lookups.getSpecialties();

            const specialties = response.data.specialties;
            commit("setSpecialties", specialties);
        } catch (error) {
            const err = error.response.data;
            alert.alert(`${err.message} [${err.statusCode}]`, "warning");
        }
    },
    async getUniversities({ commit }) {
        try {
            const response = await dataService.lookups.getUniversities();

            const universities = response.data.universities;
            commit("setUniversities", universities);
        } catch (error) {
            const err = error.response.data;
            alert.alert(`${err.message} [${err.statusCode}]`, "warning");
        }
    },
    async getVacationTypes({ commit }) {
        try {
            const response = await dataService.lookups.getVacationTypes();
            const vacationTypes = response.data.vacationsTypes;
            commit("setVacationTypes", vacationTypes);
        } catch (error) {
            const err = error.response.data;
            alert.alert(`${err.message} [${err.statusCode}]`, "warning");
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};