import dataService from "../../api";
import { startLoading, stopLoading, serverErrorMessage,showNotification, showAlert, catchHandler } from "../../utils/helpers";


const state = {

    leaves: [],
    totalItems: 0,
    leave: {},

};

const getters = {
    index: state => employeeLeaveId => state.leaves.findIndex(i => i.employeeLeaveId === employeeLeaveId)

};

const mutations = {

    setLeaves: (state, r) => (state.leaves = r),
    setTotalItems: (state, r) => (state.totalItems = r),
    setLeave: (state, r) => (state.leave = r),
    add: (state, r) => state.leaves.unshift(r),
    delete: (state, index) => state.leaves.splice(index, 1),

};

const actions = {


    async getLeaves({ commit }, params) {
        try {
            const paramsString = Object.entries(params)
                .filter(([, val]) => val !== null)
                .map(([key, val]) => `${key}=${val}`)
                .join("&");
            startLoading()
            const response = await dataService.leaves.getLeaves(paramsString);
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            commit("setLeaves", response.data.leaves);

            commit("setTotalItems", response.data.totalItems);


        } catch (error) {
            stopLoading()
            catchHandler(error);
        }
    },
    async getDetails({ commit }, id) {
        try {

            startLoading()
            const response = await dataService.leaves.getDetails(id);
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            commit("setLeave", response.data.leave);

        } catch (error) {
            stopLoading()
            catchHandler(error);
        }
    },
    async getVacationsBalance() {
        try {
            const response = await dataService.leaves.getVacationsBalance();
            return Promise.resolve(response.data);

        } catch (error) {
            const err = error.response.data;
            alert.alert(`${err.message} [${err.statusCode}]`, "warning");
        }
    },
    async add({ commit }, payload) {
        try {

            let formdata = new FormData()
            formdata.append("employeeVacationBalanceId", payload.employeeVacationBalanceId)
            formdata.append("vacationTypeId", payload.vacationTypeId)
            formdata.append("startDate", payload.startDate)
            formdata.append("endDate", payload.endDate)
            formdata.append("duration", payload.duration)
            formdata.append("description", payload.description)
            formdata.append("attachmentName", payload.attachmentName)
            formdata.append("file", payload.file)
            formdata.append("hasOffical", payload.hasOffical)
            formdata.append("officialHoliday", payload.officialHoliday)

            startLoading()
            const response = await dataService.leaves.add(formdata);
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            showNotification(response.data.message);
            commit("add", response.data.employee);


        } catch (error) {
            stopLoading()
            catchHandler(error);
            return Promise.reject(error);
        }
    },
    async delete({ commit, getters }, id) {
        try {
            startLoading()
            const response = await dataService.leaves.delete(id);
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            showNotification(response.data.message);


            const index = getters.index(id);
            commit("delete", index);
        } catch (error) {
            stopLoading()
            catchHandler(error);
        }
    },
    async getForEdit(_, id) {
        try {
            startLoading()
            const response = await dataService.leaves.getForEdit(id);
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            return Promise.resolve(response.data.leave);

        } catch (error) {
            stopLoading()
            catchHandler(error);
        }
    },
    async edit({ commit, getters }, { id, payload }) {
        try {
            let formdata = new FormData()
            formdata.append("employeeVacationBalanceId", payload.employeeVacationBalanceId)
            formdata.append("vacationTypeId", payload.vacationTypeId)
            formdata.append("startDate", payload.startDate)
            formdata.append("endDate", payload.endDate)
            formdata.append("duration", payload.duration)
            formdata.append("description", payload.description)
            formdata.append("attachmentName", payload.attachmentName)
            formdata.append("file", payload.file)
            formdata.append("hasOffical", payload.hasOffical)
            formdata.append("officialHoliday", payload.officialHoliday)
            startLoading()
            const response = await dataService.leaves.edit(id, formdata);
            stopLoading()

            if (!response.data.statusCode) {
                showAlert(serverErrorMessage);
                return;
            }

            showNotification(response.data.message);

            const index = getters.index(id);
         
            commit("edit", { index, r: response.data.leave });

            return Promise.resolve()
        } catch (error) {
            stopLoading()
            catchHandler(error);
            return Promise.reject(error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
