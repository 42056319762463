import Vue from "vue";

Vue.filter("status", (str) => {
    switch (str) {
        case 10:
            return "مدخل";
        case 1:
            return "مفعل";
        case 2:
            return "مجمد";
        case 4:
            return "مرفوض";
        case 5:
            return "معتمد";
        case 8:
            return "مستخدم";
        case 11:
            return "منتهي";
        case 16:
            return "تم الرد";
       

    }
});

Vue.filter("gender", (str) => {
    switch (str) {
        case 1:
            return "ذكر";
        case 2:
            return "انثي ";
    }
});
Vue.filter("boolString", (str) => {
    switch (str) {
        case 0:
            return "تنتهي اليوم";
        case 1:
            return "تنتهي بالغد ";
        case 2:
            return "تنتهي بعد يومان ";
        case 4:
            return "اكتر من يومان ";
        case 5:
            return "منتهيه مسبقا ";
    }
});

Vue.filter("userTypes", (str) => {
    switch (str) {
        case 1001:
            return "إداري";
        case 1003:
            return " مدير قسم";
        case 1002:
            return "مستخدم الموارد البشرية";
    }
});



Vue.filter("null", (str) => {
    if (str === "" || str === null) return "لا يوجد";
    else return str;
});