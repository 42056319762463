<template>
	<v-container fluid class="ma-0">
		<div class="container-title">
			<v-breadcrumbs class="page-title" :items="items"></v-breadcrumbs>
		</div>
		<v-row>
			<v-col cols="12" class="pa-0">
				<v-fade-transition mode="out-in">
					<slot />
				</v-fade-transition>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
			},
			subtitle: {
				type: String,
			},
			items: {
				type: Array,
			},
		},
	};
</script>

<style lang="scss" scoped>
	.container-title {
		margin: 20px 0px 40px 0px;
	}
	.page-title {
		color: #3b3f5c !important;
		letter-spacing: 0;
		display: inline;
		font-size: 3em !important;
		margin-block-start: 0.67em;
		margin-block-end: 0.67em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
		// border-left: 3px solid #b6232b;
		padding-left: 5px;
	}

	.v-breadcrumbs__item {
		/* size: 2px; */
		font-size: 1.5em !important;
	}
	.v-breadcrumbs li {
		/* size: 2px; */
		font-size: 1.5em !important;
	}
</style>
