import apiClient from "../apiClient";

const resource = "Lookups";

export default {
    getLookups: () => apiClient.get(`${resource}`),
    getSpecialties: () => apiClient.get(`${resource}/Specialties`),
    getUniversities: () => apiClient.get(`${resource}/Universities`),
    getVacationTypes: () => apiClient.get(`${resource}/VacationTypes`),

};