import security from "./modules/security";
import employee from "./modules/employee";
import health from "./modules/health";
import lookups from "./modules/lookups";
import courses from "./modules/courses";
import qualifications from "./modules/qualifications";
import leaves from "./modules/leaves";
import complaints from "./modules/complaints";

export default {

    security,
    employee,
    health,
    lookups,
    courses,
    qualifications,
    leaves,
    complaints,

};