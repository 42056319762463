export const state = {
    show: true,

    navItems: [
        {
            icon: "mdi-home",
            title: "الرئيسية",
            link: "/dashboard",
        },
        {
            icon: "mdi-folder-open",
            title: " ملف الموظف",
            link: "/employee",
            //code: 2000,

        },
        {
            icon: "mdi-stethoscope",
            title: " البيانات الصحية ",
            link: "/health",
            //code: 2000,

        },
        {
            icon: "mdi-certificate",
            title: " الدورات ",
            link: "/courses",
            //code: 2000,

        },
        {
            icon: "mdi-school",
            title: " المؤهلات ",
            link: "/qualifications",
            //code: 2000,

        },
        {
            icon: "mdi-file-document",
            title: " الاجازات ",
            link: "/leaves",
            //code: 2000,

        },
        {
            icon: "mdi-clock",
            title: "الحضور",
            link: "/clockin",
            //code: 2000,

        },
        // {
        //     icon: "mdi-book-open-outline",
        //     title: " الشكاوي والمقترحات ",
        //     link: "/complaints",
        //     //code: 2000,

        // },

    ],
};

export const getters = {
    isVisibleSideNav: (state) => state.show,
};

export const mutations = {
    setSidenavVisibility: (state, payload) => {
        state.show = payload !== undefined ? payload : !state.show;
    },
};

export const actions = {
    setSidenavVisibility: ({ commit }, payload) => {
        commit("setSidenavVisibility", payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};