import axios from "axios";
import store from '../store';

const instance = axios.create();

instance.defaults.baseURL = "api";

instance.interceptors.request.use((config) => {
    store.commit("security/setLastRequestOn");
    return config;
});

export default instance;