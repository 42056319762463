import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from "../layouts/Layout.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: "dashboard",
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('../views/Dashboard.vue')
            },
            {
                path: "employee",
                name: "employee",
                component: () =>
                    import("../views/employee/Index.vue"),
            },
            {
                path: "health",
                name: "health",
                component: () =>
                    import("../views/health/Index.vue"),
            },
            {
                path: "courses",
                name: "courses",
                component: () =>
                    import("../views/courses/Index.vue"),
            },
            {
                path: "qualifications",
                name: "qualifications",
                component: () =>
                    import("../views/qualifications/Index.vue"),
            },

            {
                path: "leaves",
                name: "leaves",
                component: () =>
                    import("../views/leaves/Index.vue"),
            },
            {
                path: "complaints",
                name: "complaints",
                component: () =>
                    import("../views/complaints/Index.vue"),
            },
            {
                path: "clockin",
                name: "clockin",
                component: () =>
                    import("../views/Clockin/index.vue"),
            },
     
        ]
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
