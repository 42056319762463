import apiClient from "../apiClient";

const resource = "Leaves";

export default {
    getLeaves: params => apiClient.get(`${resource}/Leaves?${params}`),
    getDetails: id => apiClient.get(`${resource}/${id}/details`),
    getVacationsBalance: () => apiClient.get(`${resource}/VacationsBalance`),
    add: payload => apiClient.post(`${resource}`, payload, { 'Content-Type': 'multipart/form-data' }),
    delete: id => apiClient.delete(`${resource}/${id}`),
    getForEdit: id => apiClient.get(`${resource}/${id}`),
    edit: (id, payload) => apiClient.put(`${resource}/${id}`, payload, { 'Content-Type': 'multipart/form-data' }),

};
